import React from 'react';
import { Route } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Dashboard as AppdefDashboard, Manage as AppdefManage } from 'cccisd-laravel-appdefs';

import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Progress, Quest, ViewData } from 'cccisd-laravel-assignment';

// Layouts
import FixedLayout from './layouts/FixedLayout';
import FluidLayout from './layouts/FluidLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import CurrentAssignmentPlan from './pages/CurrentAssignmentPlan';
import Student from './pages/Student';
import Teacher from './pages/Teacher';
import Welcome from './pages/Welcome'; // Include it into main bundle
// import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

const Fortress = window.cccisd.fortress;
const AppDefinitions = window.cccisd.appDefs;

// Bind FixedLayout by default
const AppRoute = defaultProps({ layout: FluidLayout })(Route);

const match = {
    AppdefDashboard,
    AppdefManage,
    CurrentAssignmentPlan,
    Progress,
    Student,
    Teacher,
    ViewData,
};

const layoutMatch = {
    FixedLayout,
    FluidLayout,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? layoutMatch[route.layout] : FluidLayout}
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact layout={FixedLayout} />
        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={null} />

        {/* Laravel packages routes */}
        {AdminRoutes(FluidLayout)}

        <AppRoute path="/account" component={Nexus} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={AuthRedirect} componentProps={{ isComponentOnly: true }} layout={FixedLayout} />
    </Switch>
);
