import React from 'react';
import PropTypes from 'prop-types';
import NavBar from 'js/components/NavBar';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import Footer from 'cccisd-footer';
import style from './style.css';

var AppDefs = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;

export default class Component extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    render() {
        return (
            <div className={style.body}>
                <div className={style.content}>
                    <NavBar className="container" />
                    {Fortress.auth() &&
                        !['learner', 'instructor'].includes(Fortress.user.acting.role.handle) && (
                            <div className={style.secondaryNav}>
                                <SecondaryNav
                                    className="container"
                                    navs={AppDefs.navs}
                                    routes={AppDefs.routes}
                                />
                            </div>
                        )}
                    <div className="container">{this.props.children}</div>
                </div>
                <div className={style.footer}>
                    <Footer className="container" />
                </div>
            </div>
        );
    }
}
