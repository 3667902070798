import React from 'react';
import { Playlist } from 'cccisd-laravel-flow';
import Assignment from 'cccisd-laravel-assignment';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

class Teacher extends React.Component {
    onComplete = async () => {
        // log user out
        window.location = Boilerplate.url('/api/user/logout');
        return window.location;
    };

    render() {
        let timepoint = +Fortress.user.acting.role_data.current_timepoint;
        timepoint = Number.isNaN(timepoint) ? 0 : timepoint;

        let handle = Fortress.user.acting.role_data.quest;
        let assignment = Appdefs.assignments[handle];

        if (assignment) {
            return (
                <Assignment
                    flowLists={assignment.flowLists}
                    flowProps={{ responseSet: timepoint }}
                    handle={handle}
                    languages={assignment.languages}
                    onComplete={this.onComplete}
                    pawnHash={Fortress.user.acting.random_hash}
                    pawnId={Fortress.user.acting.id}
                    shouldSaveLanguage={false}
                    shouldSaveFlowList={false}
                />
            );
        }

        const flowList = [
            {
                handle,
                pawnId: Fortress.user.acting.id,
                pawnHash: Fortress.user.acting.random_hash,
                responseSet: timepoint,
            },
        ];

        return <Playlist flows={flowList} onComplete={this.onComplete} />;
    }
}
export default Teacher;
