import React from 'react';
import PropTypes from 'prop-types';
import { NavBar as BoilerplateNavBar, NavLink } from 'cccisd-laravel-boilerplate';

const Fortress = window.cccisd && window.cccisd.fortress;

const NavBar = ({ className }) => {
    const rolesWithManage = ['uberadmin', 'orgAdmin', 'guAdmin'];

    return (
        <BoilerplateNavBar
            className={className}
            hideSeparateRoleSwitcher
            logo="Social Skill Training Study Research Portal"
        >
            {Fortress && Fortress.auth() && rolesWithManage.includes(Fortress.user.acting.role.handle) && (
                <NavLink to="/manage">Manage</NavLink>
            )}
        </BoilerplateNavBar>
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
