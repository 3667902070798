import React from 'react';
// import PropTypes from 'prop-types';
// import Loader from 'cccisd-loader';
import { Playlist } from 'cccisd-laravel-flow';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

class Student extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    state = {
        // loading: true,
    };

    onComplete = async () => {
        // log user out
        window.location = Boilerplate.url('/api/user/logout');
        return window.location;
    };

    render() {
        let timepoint = +Fortress.user.acting.role_data.current_timepoint;
        timepoint = Number.isNaN(timepoint) ? 0 : timepoint;

        const flowList = [
            {
                handle: Fortress.user.acting.role_data.quest,
                pawnId: Fortress.user.acting.id,
                pawnHash: Fortress.user.acting.random_hash,
                responseSet: timepoint,
            },
        ];

        return <Playlist flows={flowList} onComplete={this.onComplete} />;
    }
}
export default Student;
